import { TOption } from "src/components/UI/Select/Select";

export const courts: TOption[] = [
  {
    value: "Бабушкинский районный суд",
    label: "Бабушкинский районный суд",
  },
  {
    value: "Басманный районный суд",
    label: "Басманный районный суд",
  },
  {
    value: "Бутырский районный суд",
    label: "Бутырский районный суд",
  },
  {
    value: "Гагаринский районный суд",
    label: "Гагаринский районный суд",
  },
  {
    value: "Головинский районный суд",
    label: "Головинский районный суд",
  },
  {
    value: "Дорогомиловский районный суд",
    label: "Дорогомиловский районный суд",
  },
  {
    value: "Замоскворецкий районный суд",
    label: "Замоскворецкий районный суд",
  },
  {
    value: "Зеленоградский районный суд",
    label: "Зеленоградский районный суд",
  },
  {
    value: "Зюзинский районный суд",
    label: "Зюзинский районный суд",
  },
  {
    value: "Измайловский районный суд",
    label: "Измайловский районный суд",
  },
  {
    value: "Коптевский районный суд",
    label: "Коптевский районный суд",
  },
  {
    value: "Кузьминский районный суд",
    label: "Кузьминский районный суд",
  },
  {
    value: "Кунцевский районный суд",
    label: "Кунцевский районный суд",
  },
  {
    value: "Лефортовский районный суд",
    label: "Лефортовский районный суд",
  },
  {
    value: "Люблинский районный суд",
    label: "Люблинский районный суд",
  },
  {
    value: "Мещанский районный суд",
    label: "Мещанский районный суд",
  },
  {
    value: "Московский городской суд",
    label: "Московский городской суд",
  },
  {
    value: "Нагатинский районный суд",
    label: "Нагатинский районный суд",
  },
  {
    value: "Никулинский районный суд",
    label: "Никулинский районный суд",
  },
  {
    value: "Останкинский районный суд",
    label: "Останкинский районный суд",
  },
  {
    value: "Перовский районный суд",
    label: "Перовский районный суд",
  },
  {
    value: "Преображенский районный суд",
    label: "Преображенский районный суд",
  },
  {
    value: "Пресненский районный суд",
    label: "Пресненский районный суд",
  },
  {
    value: "Савёловский районный суд",
    label: "Савёловский районный суд",
  },
  {
    value: "Симоновский районный суд",
    label: "Симоновский районный суд",
  },
  {
    value: "Солнцевский районный суд",
    label: "Солнцевский районный суд",
  },
  {
    value: "Таганский районный суд",
    label: "Таганский районный суд",
  },
  {
    value: "Тверской районный суд",
    label: "Тверской районный суд",
  },
  {
    value: "Тимирязевский районный суд",
    label: "Тимирязевский районный суд",
  },
  {
    value: "Троицкий районный суд",
    label: "Троицкий районный суд",
  },
  {
    value: "Тушинский районный суд",
    label: "Тушинский районный суд",
  },
  {
    value: "Хамовнический районный суд",
    label: "Хамовнический районный суд",
  },
  {
    value: "Хорошёвский районный суд",
    label: "Хорошёвский районный суд",
  },
  {
    value: "Черёмушкинский районный суд",
    label: "Черёмушкинский районный суд",
  },
  {
    value: "Чертановский районный суд",
    label: "Чертановский районный суд",
  },
  {
    value: "Щербинский районный суд",
    label: "Щербинский районный суд",
  },
];
