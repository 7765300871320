export interface MockSectionActs {
  [key: string]: number[];
}

export const mockSectionActs: MockSectionActs = {
  30: [1, 2, 3],
  105: [1, 2],
  106: [],
  107: [1, 2],
  108: [1, 2],
  109: [1, 2, 3],
  110: [1, 2],
  110.1: [1, 2, 3, 4, 5, 6],
  110.2: [1, 2],
  111: [1, 2, 3, 4],
  112: [1, 2],
  113: [],
  114: [1, 2],
  115: [1, 2],
  116: [],
  116.1: [1, 2],
  117: [1, 2],
  118: [1, 2],
  119: [1, 2],
  120: [1, 2],
  121: [1, 2],
  122: [1, 2, 3, 4],
  123: [1, 3],
  124: [1, 2],
  124.1: [1, 2],
  125: [],
  126: [1, 2, 3],
  127: [1, 2, 3],
  127.1: [1, 2, 3],
  127.2: [1, 2, 3],
  128: [1, 2],
  128.1: [1, 2, 3, 4, 5],
  131: [1, 2, 3, 4, 5],
  132: [1, 2, 3, 4, 5],
  133: [1, 2, 3],
  134: [1, 2, 3, 4, 5, 6],
  135: [1, 2, 3, 4, 5],
  136: [],
  137: [1, 2, 3],
  138: [1, 2],
  138.1: [],
  139: [1, 2, 3],
  140: [],
  141: [1, 2, 3],
  141.1: [1, 2],
  142: [1, 2, 3],
  142.1: [],
  142.2: [1, 2, 3],
  143: [1, 2, 3],
  144: [1, 2, 3],
  144.1: [],
  145: [],
  145.1: [1, 2, 3],
  146: [1, 2, 3],
  147: [1, 2],
  148: [1, 2, 3, 4],
  149: [],
  150: [1, 2, 3, 4],
  151: [1, 2, 3],
  151.1: [],
  151.2: [1, 2],
  153: [],
  154: [],
  155: [],
  156: [],
  157: [1, 2],
  158: [1, 2, 3, 4],
  158.1: [],
  159: [1, 2, 3, 4, 5, 6, 7],
  159.1: [1, 2, 3, 4],
  159.2: [1, 2, 3, 4],
  159.3: [1, 2, 3, 4],
  159.5: [1, 2, 3, 4],
  159.6: [1, 2, 3, 4],
  160: [1, 2, 3, 4],
  161: [1, 2, 3],
  162: [1, 2, 3, 4],
  163: [1, 2, 3],
  164: [1, 2],
  165: [1, 2],
  166: [1, 2, 3, 4],
  167: [1, 2],
  168: [],
  169: [1, 2],
  170: [],
  170.1: [1, 2, 3, 4, 5],
  170.2: [1, 2],
  171: [1, 2],
  171.1: [1, 1.1, 2, 3, 4, 5, 6],
  171.2: [1, 2, 3],
  171.3: [1, 2],
  171.4: [],
  171.5: [],
  172: [1, 2],
  172.1: [1, 2],
  172.2: [1, 2],
  172.3: [1, 2],
  172.4: [1, 2, 3],
  173.1: [1, 2],
  173.2: [1, 2],
  174: [1, 2, 3, 4],
  174.1: [1, 2, 3, 4],
  175: [1, 2, 3],
  176: [1, 2],
  177: [],
  178: [1, 2, 3],
  179: [1, 2],
  180: [1, 2, 3, 4],
  181: [1, 2],
  183: [1, 2, 3, 4],
  184: [1, 2, 3, 4, 5],
  185: [1, 2, 3],
  185.1: [],
  185.2: [1, 2, 3],
  185.3: [1, 2],
  185.4: [1, 2],
  185.5: [1, 2],
  185.6: [1, 2],
  186: [1, 2, 3],
  187: [1, 2],
  189: [1, 2, 3],
  190: [],
  191: [1, 2, 3, 4, 5],
  191.1: [1, 2, 3],
  192: [],
  193: [1, 2],
  193.1: [1, 2, 3],
  194: [1, 2, 3, 4],
  195: [1, 1.1, 2, 2.1, 3, 4, 5],
  196: [1, 2],
  197: [],
  198: [1, 2],
  199: [1, 2],
  199.1: [1, 2],
  199.2: [1, 2],
  199.3: [1, 2],
  199.4: [1, 2],
  200.1: [1, 2],
  200.2: [1, 2, 3],
  200.3: [1, 2],
  200.4: [1, 2],
  200.5: [1, 2, 3, 4, 5, 6],
  200.6: [1, 2, 3],
  200.7: [1, 2, 3, 4, 5, 6, 7, 8],
  201: [1, 2],
  201.1: [1, 2],
  201.2: [1, 2],
  201.3: [],
  202: [1, 2],
  203: [1, 2],
  204: [1, 2, 3, 4, 5, 6, 7, 8],
  204.1: [1, 2, 3, 4],
  204.2: [1, 2],
  205: [1, 2, 3],
  205.1: [1, 1.1, 2, 3, 4],
  205.2: [1, 2],
  205.3: [],
  205.4: [1, 2],
  205.5: [1, 2],
  205.6: [],
  206: [1, 2, 3, 4],
  207: [1, 2, 3, 4],
  207.1: [],
  207.2: [1, 2],
  207.3: [1, 2, 3],
  208: [1, 2, 3],
  209: [1, 2, 3],
  210: [1, 1.1, 2, 3, 4],
  210.1: [],
  211: [1, 2, 3, 4],
  212: [1, 1.1, 2, 3, 4],
  212.1: [],
  213: [1, 2, 3],
  214: [1, 2],
  215: [1, 2, 3],
  215.1: [1, 2],
  215.2: [1, 2, 3],
  215.3: [1, 2, 3, 4, 5],
  215.4: [1, 2],
  216: [1, 2, 3],
  217: [1, 2, 3],
  217.1: [1, 2, 3],
  217.2: [1, 2, 3],
  218: [],
  219: [1, 2, 3],
  220: [1, 2, 3],
  221: [1, 2, 3],
  222: [1, 2, 3, 4, 5, 6, 7],
  222.1: [1, 2, 3, 4, 5, 6],
  222.2: [1, 2, 3, 4, 5, 6],
  223: [1, 2, 3, 4],
  223.1: [1, 2, 3],
  224: [1, 2],
  225: [1, 2],
  226: [1, 2, 3, 4],
  226.1: [1, 2, 3],
  227: [1, 2, 3],
  228: [1, 2, 3],
  228.1: [1, 2, 3, 4, 5],
  228.2: [1, 2],
  228.3: [1, 2],
  228.4: [1, 2],
  229: [1, 2, 3, 4],
  229.1: [1, 2, 3, 4],
  230: [1, 2, 3, 4],
  230.1: [1, 2, 3],
  230.2: [1, 2],
  231: [1, 2],
  232: [1, 2, 3],
  233: [],
  234: [1, 2, 3, 4],
  234.1: [1, 2, 3],
  234.2: [1, 2, 3],
  235: [1, 2],
  235.1: [1, 2],
  236: [1, 2, 3],
  237: [1, 2],
  238: [1, 2, 3],
  238.1: [1, 1.1, 2, 3],
  239: [1, 2, 3],
  240: [1, 2, 3],
  240.1: [],
  241: [1, 2, 3],
  242: [1, 2, 3],
  242.1: [1, 2],
  242.2: [1, 2],
  243: [1, 2],
  243.1: [],
  243.2: [1, 2, 3],
  243.3: [1, 2],
  243.4: [1, 2],
  244: [1, 2],
  245: [1, 2],
  246: [],
  247: [1, 2, 3],
  248: [1, 2],
  249: [1, 2],
  250: [1, 2, 3],
  251: [1, 2, 3],
  252: [1, 2, 3],
  253: [1, 2, 3],
  254: [1, 2, 3],
  255: [1, 2, 3],
  256: [1, 2, 3],
  257: [],
  258: [1, 2],
  258.1: [1, 1.1, 2, 2.1, 3, 3.1],
  259: [],
  260: [1, 2, 3],
  261: [1, 3, 4],
  262: [],
  263: [1, 1.1, 1.2, 2, 2.1, 3, 4],
  263.1: [1, 2, 3, 4],
  264: [1, 2, 3, 4, 5, 6],
  264.1: [1, 2],
  264.2: [1, 2],
  264.3: [1, 2],
  266: [1, 2, 3],
  267: [1, 2, 3, 4, 5, 6],
  267.1: [],
  268: [1, 2, 3],
  270: [],
  271: [],
  271.1: [1, 2],
  272: [1, 2, 3, 4],
  273: [1, 2, 3],
  274: [1, 2],
  274.1: [1, 2, 3, 4, 5],
  274.2: [1, 2],
  275: [],
  275.1: [],
  276: [],
  277: [],
  278: [],
  279: [],
  280: [1, 2],
  280.1: [1, 2],
  280.2: [],
  280.3: [1, 2],
  280.4: [1, 2, 3],
  281: [1, 2, 3],
  281.1: [1, 2, 3, 4],
  281.2: [],
  281.3: [1, 2],
  282: [1, 2],
  282.1: [1, 1.1, 2, 3],
  282.2: [1, 1.1, 2, 3],
  282.3: [1, 2],
  282.4: [1, 2],
  283: [1, 2],
  283.1: [1, 2],
  283.2: [1, 2, 3, 4],
  284: [],
  284.1: [1, 2, 3],
  284.2: [],
  284.3: [],
  285: [1, 2, 3],
  285.1: [1, 2],
  285.2: [1, 2],
  285.3: [1, 2, 3],
  285.4: [1, 2],
  285.5: [1, 2],
  285.6: [],
  286: [1, 2, 3, 4, 5],
  286.1: [1, 2],
  287: [1, 2, 3],
  288: [],
  289: [],
  290: [1, 2, 3, 4, 5, 6],
  291: [1, 2, 3, 4, 5],
  291.1: [1, 2, 3, 4, 5],
  291.2: [1, 2],
  292: [1, 2],
  292.1: [1, 2],
  293: [1, 1.1, 2, 3],
  294: [1, 2, 3],
  295: [],
  296: [1, 2, 3, 4],
  297: [1, 2],
  298.1: [1, 2, 3],
  299: [1, 2, 3],
  300: [],
  301: [1, 2, 3],
  302: [1, 2, 3, 4],
  303: [1, 2, 3, 4],
  304: [],
  305: [1, 2],
  306: [1, 2, 3],
  307: [1, 2],
  308: [],
  309: [1, 2, 3, 4],
  310: [],
  311: [1, 2],
  312: [1, 2],
  313: [1, 2, 3],
  314: [1, 2, 3],
  314.1: [1, 2],
  315: [1, 2],
  316: [1, 2],
  317: [],
  318: [1, 2],
  319: [],
  320: [1, 2],
  321: [1, 2, 3],
  322: [1, 2, 3],
  322.1: [1, 2],
  322.2: [],
  322.3: [],
  323: [1, 2],
  324: [],
  325: [1, 2, 3],
  325.1: [1, 2],
  326: [1, 2],
  327: [1, 2, 3, 4, 5],
  327.1: [1, 2, 3, 4, 5, 6],
  327.2: [1, 2, 3],
  328: [1, 2],
  329: [],
  330: [1, 2],
  330.1: [1, 2, 3],
  330.2: [],
  330.3: [1, 2],
  332: [1, 2, 2.1, 2.2, 3],
  333: [1, 2, 3],
  334: [1, 2, 3],
  335: [1, 2, 3],
  336: [1, 2],
  337: [1, 2, 2.1, 3, 3.1, 4, 5],
  338: [1, 2, 3],
  339: [1, 2, 3],
  340: [1, 2, 3, 4, 5],
  341: [1, 2, 3, 4, 5],
  342: [1, 2, 3, 4, 5],
  343: [1, 2],
  344: [1, 2, 3],
  345: [],
  346: [1, 2, 3],
  347: [1, 2],
  348: [1, 2],
  349: [1, 2, 3],
  350: [1, 2, 3],
  351: [],
  352: [],
  352.1: [],
  353: [1, 2],
  354: [1, 2],
  354.1: [1, 2, 3, 4],
  355: [],
  356: [1, 2],
  356.1: [1, 2, 3, 4],
  357: [],
  358: [],
  359: [1, 2, 3],
  360: [1, 2],
  361: [1, 2, 3],
};
