import { useLocation, useRoutes } from "react-router-dom";
import { useEffect } from "react";

import { routes } from "./routes";

function App() {
  const content = useRoutes(routes);
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return content;
}

export default App;
