import React, { lazy, Suspense } from "react";
import { RouteObject } from "react-router";

import LoadingScreen from "./components/smart/LoadingScreen";
// import AboutUs from "./pages/AboutUs";
import Home from "./pages/Home/Home";
import ContactUs from "./pages/ContactUs";
// import Information from "./pages/Information";
import MainPage from "./pages/MainPage";
import OnFreedom from "./pages/OnFreedom";
import Profile from "./pages/Profile/Profile";
import PrefetchProvider from "./providers/PrefetchProvider";

const Loadable =
  <P extends object>(Component: React.ComponentType<P>) =>
  // eslint-disable-next-line react/display-name
  (props: P) => {
    return (
      <Suspense fallback={<LoadingScreen />}>
        <Component {...props} />
      </Suspense>
    );
  };

export default Loadable;

const MainLayout = Loadable(
  lazy(() => import("./components/smart/MainLayout"))
);
const Error = Loadable(lazy(() => import("./components/smart/ErrorPage")));

export const routes: RouteObject[] = [
  {
    path: "/",
    element: <PrefetchProvider />,
    children: [
      {
        path: "/",
        element: <MainLayout headerIsColored={false} />,
        children: [
          {
            path: "/",
            element: <Home />,
          },
        ],
      },
      {
        path: "/",
        element: <MainLayout headerIsColored={true} />,
        children: [
          {
            path: "/calculator",
            element: <MainPage />,
          },
          // {
          //   path: "information",
          //   element: <Information />,
          // },
          // {
          //   path: "about-us",
          //   element: <AboutUs />,
          // },
          {
            path: "contact-us",
            element: <ContactUs />,
          },
          {
            path: "freedom",
            element: <OnFreedom />,
          },
          {
            path: "profile",
            element: <Profile />,
          },
          {
            path: "*",
            element: <Error />,
          },
        ],
      },
    ],
  },
];

// export const GetRoutes = (nestedRouteArray: RouteObject[] = []) => {
//   const result: Array<string | string[]> = []
//   const routesArray = nestedRouteArray.length === 0 ? routes : nestedRouteArray

//   routesArray.forEach((route) => {
//     if (Object.prototype.hasOwnProperty.call(route, 'children')) {
//       result.push(route.path as string)
//       result.push(GetRoutes(route.children))
//     } else {
//       result.push(route.path as string)
//     }
//   })

//   return [...new Set(result.flat())].filter((route) => route !== '*')
// }
