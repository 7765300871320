import { FC } from "react";

import { IconType } from "./types/icon.types";

const LockIcon: FC<IconType> = (props) => {
  const { fill } = props;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C14.6888 2 16.8818 4.12231 16.9954 6.78311L17 7V10C18.6569 10 20 11.3431 20 13V19C20 20.6569 18.6569 22 17 22H7C5.34315 22 4 20.6569 4 19L4 13C4 11.3431 5.34315 10 7 10L7 7C7 4.23858 9.23858 2 12 2ZM17 11.9998L6.99996 11.9998C6.44768 11.9998 5.99996 12.4475 5.99996 12.9998L5.99996 18.9998C5.99996 19.5521 6.44768 19.9998 6.99996 19.9998H17C17.5522 19.9998 18 19.5521 18 18.9998V12.9998C18 12.4475 17.5522 11.9998 17 11.9998ZM12.1762 4.00505L11.9999 3.99996C10.4022 3.99996 9.09624 5.24888 9.005 6.82369L8.9999 6.99996V9.99996H14.9999V6.99996C14.9999 5.40228 13.751 4.0963 12.1762 4.00505L11.9999 3.99996L12.1762 4.00505Z"
        fill={fill || "#0C64C5"}
      />
    </svg>
  );
};

export default LockIcon;
