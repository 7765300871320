import React, { useEffect } from "react";
import "./Profile.scss";

import PdfSvg from "src/assets/images/pdf.svg";
import DownloadSvg from "src/assets/images/download.svg";

import { H } from "../../components/UI/Text/H";
import { useSelector } from "../../store";
import { profileState } from "../../store/slices/Auth/auth.selectors";
import {
  firstNameLastNameHandler,
  toDate,
} from "../../utils/helpers/string.helpers";
import { useProfileHistoryMutation } from "../../store/api/auth.api";
import LoadingScreen from "../../components/smart/LoadingScreen";

interface IEmptyData {
  text: string;
  action: () => void;
}

const ProfileInfoItem = ({
  title,
  value,
  emptyData,
}: {
  title: string;
  value?: string;
  emptyData: IEmptyData;
}) => {
  return (
    <H className="profileInfoItem" variant={"sm"}>
      {title}{" "}
      {value ? value : <span onClick={emptyData.action}>{emptyData.text}</span>}
    </H>
  );
};

const Profile = () => {
  const [getProfileReports, { data: reports, isLoading }] =
    useProfileHistoryMutation();
  const profile = useSelector(profileState);

  useEffect(() => {
    getProfileReports();
  }, [profile]);
  return (
    <div className="profile">
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <div className="profile-card">
            <H variant={"lg"}>{firstNameLastNameHandler(profile)}</H>
            <div className="profile-card-item">
              <div className="profile-card-item-flex">
                <ProfileInfoItem
                  title={"Email:"}
                  value={profile?.email}
                  emptyData={{ action: () => null, text: "Добавить почту" }}
                />
                <ProfileInfoItem
                  title={"Телефон:"}
                  value={profile?.phone}
                  emptyData={{ action: () => null, text: "Добавить телефон" }}
                />
              </div>
              <ProfileInfoItem
                title={"Карта для оплаты:"}
                value={profile?.card}
                emptyData={{ action: () => null, text: "Добавить карту" }}
              />
            </div>
            <H className="profile-card-id" variant={"lg"}>
              ID: {profile?.id}
            </H>
            <H className="profile-card-pass" variant={"sm"}>
              Поменять пароль
            </H>
          </div>

          <div className="profile-reports">
            <H variant={"lg"}>Отчеты</H>
            <div className="profile-reports-column">
              {reports?.data?.map((report, key) => (
                <div key={key} className="profile-reports-item">
                  <div className="profile-reports-item-left">
                    <img src={PdfSvg} alt="pdf" />

                    <div>
                      <H variant="md">
                        {checkIsReportVal(report.report_val)}
                        ст. {toFixedNumber(report.article)} ч.{" "}
                        {toFixedNumber(report.part)}
                      </H>
                      <H className="profile-reports-item-date" variant="sm">
                        {toDate(report.data, true)}
                      </H>
                    </div>
                  </div>
                  <div className="profile-reports-item-download">
                    <img src={DownloadSvg} alt="download" />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Profile;

function toFixedNumber(value: string) {
  return Number(value).toFixed(0);
}

function checkIsReportVal(value: string) {
  return value ? `${value}, ` : "";
}
