import React, { ReactNode } from "react";

import { downloadPDF } from "../../../utils/helpers/common";
import {
  mediaQueriesConstants,
  useMediaQuery,
} from "../../../hooks/useMediaQuery";

const PoliticsLink = ({
  children,
  className,
}: {
  children: ReactNode;
  className: string;
}) => {
  const changeMobileMenu = useMediaQuery(mediaQueriesConstants.mobile);
  return changeMobileMenu ? (
    <p className={className} onClick={downloadPDF}>
      {children}
    </p>
  ) : (
    <a
      target="_blank"
      className={className}
      href="/PersonalData.pdf"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
};

export default PoliticsLink;
