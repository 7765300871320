import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  getSessionStorageCredentials,
  getSessionStorageProfile,
} from "../../../utils/helpers/storage.helpers";
import { IProfile } from "../../../utils/types/Auth.types";

interface AuthModalState {
  isAuthModalOpen: boolean;
  isAuthenticated: boolean;
  profile: IProfile | null;
}

const sessionDefaultProfile = getSessionStorageProfile();

const initialState: AuthModalState = {
  isAuthModalOpen: false,
  isAuthenticated: !!getSessionStorageCredentials() && !!sessionDefaultProfile,
  profile: sessionDefaultProfile,
};

const slice = createSlice({
  name: "authModal",
  initialState,
  reducers: {
    updateAuthModalState: (state, action: PayloadAction<boolean>) => {
      state.isAuthModalOpen = action.payload;
    },
    updateIsAuthenticatedState: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    updateProfileState: (state, action: PayloadAction<IProfile | null>) => {
      state.profile = action.payload;
    },
  },
});

export const {
  updateAuthModalState,
  updateIsAuthenticatedState,
  updateProfileState,
} = slice.actions;

export const { reducer } = slice;
