import { TOption } from "src/components/UI/Select/Select";

export const judges: TOption[] = [
  {
    value: "Абрамова А.Ю.",
    label: "Абрамова А.Ю.",
  },
  {
    value: "Абрамова Е.Д.",
    label: "Абрамова Е.Д.",
  },
  {
    value: "Авдотьина А.А.",
    label: "Авдотьина А.А.",
  },
  {
    value: "Аверчева Н.М.",
    label: "Аверчева Н.М.",
  },
  {
    value: "Аверченко Е.П.",
    label: "Аверченко Е.П.",
  },
  {
    value: "Айнетдинова И.Р.",
    label: "Айнетдинова И.Р.",
  },
  {
    value: "Аканова М.В.",
    label: "Аканова М.В.",
  },
  {
    value: "Аккуратова И.В.",
    label: "Аккуратова И.В.",
  },
  {
    value: "Александрова С.А.",
    label: "Александрова С.А.",
  },
  {
    value: "Алныкина О.Ю.",
    label: "Алныкина О.Ю.",
  },
  {
    value: "Алтынникова Л.И.",
    label: "Алтынникова Л.И.",
  },
  {
    value: "Андроник А.В.",
    label: "Андроник А.В.",
  },
  {
    value: "Андрюхин А.Н.",
    label: "Андрюхин А.Н.",
  },
  {
    value: "Антипова А.В.",
    label: "Антипова А.В.",
  },
  {
    value: "Арбузова О.В.",
    label: "Арбузова О.В.",
  },
  {
    value: "Аринкина Н.Л.",
    label: "Аринкина Н.Л.",
  },
  {
    value: "Арнаут Д.Ю.",
    label: "Арнаут Д.Ю.",
  },
  {
    value: "Артеменко Э.С.",
    label: "Артеменко Э.С.",
  },
  {
    value: "Артемов С.А.",
    label: "Артемов С.А.",
  },
  {
    value: "Артемонова Д.А.",
    label: "Артемонова Д.А.",
  },
  {
    value: "Астафьева Е.А.",
    label: "Астафьева Е.А.",
  },
  {
    value: "Астахов С.Н.",
    label: "Астахов С.Н.",
  },
  {
    value: "Астахова Е.С.",
    label: "Астахова Е.С.",
  },
  {
    value: "Бабинова Е.Д.",
    label: "Бабинова Е.Д.",
  },
  {
    value: "Багрова О.А.",
    label: "Багрова О.А.",
  },
  {
    value: "Базаров С.Н.",
    label: "Базаров С.Н.",
  },
  {
    value: "Банник А.Е.",
    label: "Банник А.Е.",
  },
  {
    value: "Бараковская Т.Е.",
    label: "Бараковская Т.Е.",
  },
  {
    value: "Бараксанов И.В.",
    label: "Бараксанов И.В.",
  },
  {
    value: "Бахвалов А.В.",
    label: "Бахвалов А.В.",
  },
  {
    value: "Бахвалова Л.А.",
    label: "Бахвалова Л.А.",
  },
  {
    value: "Бекетова С.Ю.",
    label: "Бекетова С.Ю.",
  },
  {
    value: "Белицкий В.А.",
    label: "Белицкий В.А.",
  },
  {
    value: "Белкина В.А.",
    label: "Белкина В.А.",
  },
  {
    value: "Бельченко И.В.",
    label: "Бельченко И.В.",
  },
  {
    value: "Беляков А.А.",
    label: "Беляков А.А.",
  },
  {
    value: "Бехтерева Н.В.",
    label: "Бехтерева Н.В.",
  },
  {
    value: "Бобков А.В.",
    label: "Бобков А.В.",
  },
  {
    value: "Боднарь В.В.",
    label: "Боднарь В.В.",
  },
  {
    value: "Болотникова А.И.",
    label: "Болотникова А.И.",
  },
  {
    value: "Борисенкова Н.В.",
    label: "Борисенкова Н.В.",
  },
  {
    value: "Бочарова В.Г.",
    label: "Бочарова В.Г.",
  },
  {
    value: "Бубчикова М.В.",
    label: "Бубчикова М.В.",
  },
  {
    value: "Будигин Д.В.",
    label: "Будигин Д.В.",
  },
  {
    value: "Булгакова Е.М.",
    label: "Булгакова Е.М.",
  },
  {
    value: "Булучевская Е.А.",
    label: "Булучевская Е.А.",
  },
  {
    value: "Бунеева И.В.",
    label: "Бунеева И.В.",
  },
  {
    value: "Бутарева Е.В.",
    label: "Бутарева Е.В.",
  },
  {
    value: "Быковская О.Н.",
    label: "Быковская О.Н.",
  },
  {
    value: "Вавилова Е.В.",
    label: "Вавилова Е.В.",
  },
  {
    value: "Ванина Э.С.",
    label: "Ванина Э.С.",
  },
  {
    value: "Васильев Б.И.",
    label: "Васильев Б.И.",
  },
  {
    value: "Васильева Н.В.",
    label: "Васильева Н.В.",
  },
  {
    value: "Васина А.В.",
    label: "Васина А.В.",
  },
  {
    value: "Васюченко Т.М.",
    label: "Васюченко Т.М.",
  },
  {
    value: "Веретенников А.В.",
    label: "Веретенников А.В.",
  },
  {
    value: "Верещагина Е.И.",
    label: "Верещагина Е.И.",
  },
  {
    value: "Винедиктова Л.М.",
    label: "Винедиктова Л.М.",
  },
  {
    value: "Волков А.А.",
    label: "Волков А.А.",
  },
  {
    value: "Володихина Е.В.",
    label: "Володихина Е.В.",
  },
  {
    value: "Воронин А.В.",
    label: "Воронин А.В.",
  },
  {
    value: "Вырышева И.В.",
    label: "Вырышева И.В.",
  },
  {
    value: "Галимова А.Г.",
    label: "Галимова А.Г.",
  },
  {
    value: "Галиуллин И.З.",
    label: "Галиуллин И.З.",
  },
  {
    value: "Галиханова Е.Н.",
    label: "Галиханова Е.Н.",
  },
  {
    value: "Галкин С.А.",
    label: "Галкин С.А.",
  },
  {
    value: "Гапушина И.Ю.",
    label: "Гапушина И.Ю.",
  },
  {
    value: "Гарбар Л.В.",
    label: "Гарбар Л.В.",
  },
  {
    value: "Глух В.Н.",
    label: "Глух В.Н.",
  },
  {
    value: "Глухов А.В.",
    label: "Глухов А.В.",
  },
  {
    value: "Голубкова А.А.",
    label: "Голубкова А.А.",
  },
  {
    value: "Гончар Г.Е.",
    label: "Гончар Г.Е.",
  },
  {
    value: "Горбулина И.Б.",
    label: "Горбулина И.Б.",
  },
  {
    value: "Гордеев Д.С.",
    label: "Гордеев Д.С.",
  },
  {
    value: "Гордеева Н.С.",
    label: "Гордеева Н.С.",
  },
  {
    value: "Городницкая Е.А.",
    label: "Городницкая Е.А.",
  },
  {
    value: "Горохова Е.А.",
    label: "Горохова Е.А.",
  },
  {
    value: "Горюнова О.И.",
    label: "Горюнова О.И.",
  },
  {
    value: "Гришина Е.М.",
    label: "Гришина Е.М.",
  },
  {
    value: "Грязнова А.Н.",
    label: "Грязнова А.Н.",
  },
  {
    value: "Гудошникова Е.А.",
    label: "Гудошникова Е.А.",
  },
  {
    value: "Гуторова О.Б.",
    label: "Гуторова О.Б.",
  },
  {
    value: "Дадашова Л.А.",
    label: "Дадашова Л.А.",
  },
  {
    value: "Данилина М.Н.",
    label: "Данилина М.Н.",
  },
  {
    value: "Дёмина Е.А.",
    label: "Дёмина Е.А.",
  },
  {
    value: "Дзис М.И.",
    label: "Дзис М.И.",
  },
  {
    value: "Долгополов Д.В.",
    label: "Долгополов Д.В.",
  },
  {
    value: "Досаева Г.С.",
    label: "Досаева Г.С.",
  },
  {
    value: "Дроздова О.В.",
    label: "Дроздова О.В.",
  },
  {
    value: "Дубков К.Ю.",
    label: "Дубков К.Ю.",
  },
  {
    value: "Дударь Н.Н.",
    label: "Дударь Н.Н.",
  },
  {
    value: "Дудкин А.Ю.",
    label: "Дудкин А.Ю.",
  },
  {
    value: "Духновская З.А.",
    label: "Духновская З.А.",
  },
  {
    value: "Егорова А.Р.",
    label: "Егорова А.Р.",
  },
  {
    value: "Ежеленко Ю.А.",
    label: "Ежеленко Ю.А.",
  },
  {
    value: "Еремина (Дворжанская) Ю.О.",
    label: "Еремина (Дворжанская) Ю.О.",
  },
  {
    value: "Еремина Ю.О.",
    label: "Еремина Ю.О.",
  },
  {
    value: "Ермакова Е.В.",
    label: "Ермакова Е.В.",
  },
  {
    value: "Ермишина И.А.",
    label: "Ермишина И.А.",
  },
  {
    value: "Ершова А.Н.",
    label: "Ершова А.Н.",
  },
  {
    value: "Жребец Т.Е.",
    label: "Жребец Т.Е.",
  },
  {
    value: "Жукова О.В.",
    label: "Жукова О.В.",
  },
  {
    value: "Журавлева Н.В.",
    label: "Журавлева Н.В.",
  },
  {
    value: "Журавлева Т.Н.",
    label: "Журавлева Т.Н.",
  },
  {
    value: "Зайцев Р.Е.",
    label: "Зайцев Р.Е.",
  },
  {
    value: "Затомская О.Ю.",
    label: "Затомская О.Ю.",
  },
  {
    value: "Захарова Т.Р.",
    label: "Захарова Т.Р.",
  },
  {
    value: "Захарченко И.А.",
    label: "Захарченко И.А.",
  },
  {
    value: "Зельдина О.В.",
    label: "Зельдина О.В.",
  },
  {
    value: "Зиньковская Л.А.",
    label: "Зиньковская Л.А.",
  },
  {
    value: "Зиняков Д.Н.",
    label: "Зиняков Д.Н.",
  },
  {
    value: "Зозуля Д.В.",
    label: "Зозуля Д.В.",
  },
  {
    value: "Зорина И.В.",
    label: "Зорина И.В.",
  },
  {
    value: "Изотова Т.Ю.",
    label: "Изотова Т.Ю.",
  },
  {
    value: "Ильин А.В.",
    label: "Ильин А.В.",
  },
  {
    value: "Исаева Я.В.",
    label: "Исаева Я.В.",
  },
  {
    value: "Кабанова Н.А.",
    label: "Кабанова Н.А.",
  },
  {
    value: "Кавешников А.А.",
    label: "Кавешников А.А.",
  },
  {
    value: "Казан Е.В.",
    label: "Казан Е.В.",
  },
  {
    value: "Казанцев О.А.",
    label: "Казанцев О.А.",
  },
  {
    value: "Калашникова М.В.",
    label: "Калашникова М.В.",
  },
  {
    value: "Калашникова Н.С.",
    label: "Калашникова Н.С.",
  },
  {
    value: "Калинина Т.В.",
    label: "Калинина Т.В.",
  },
  {
    value: "Канева Е.В.",
    label: "Канева Е.В.",
  },
  {
    value: "Каракешишева Е.Н.",
    label: "Каракешишева Е.Н.",
  },
  {
    value: "Карпов А.Г.",
    label: "Карпов А.Г.",
  },
  {
    value: "Каширин С.В.",
    label: "Каширин С.В.",
  },
  {
    value: "Киприянов А.В.",
    label: "Киприянов А.В.",
  },
  {
    value: "Киреев А.И.",
    label: "Киреев А.И.",
  },
  {
    value: "Кириченко К.Е.",
    label: "Кириченко К.Е.",
  },
  {
    value: "Киселькова О.Ю.",
    label: "Киселькова О.Ю.",
  },
  {
    value: "Кислякова Т.С.",
    label: "Кислякова Т.С.",
  },
  {
    value: "Китаева И.В.",
    label: "Китаева И.В.",
  },
  {
    value: "Клименко О.М.",
    label: "Клименко О.М.",
  },
  {
    value: "Клинцова И.В.",
    label: "Клинцова И.В.",
  },
  {
    value: "Ковалевская А.Б.",
    label: "Ковалевская А.Б.",
  },
  {
    value: "Ковалевский Ю.Ю.",
    label: "Ковалевский Ю.Ю.",
  },
  {
    value: "Козлова А.С.",
    label: "Козлова А.С.",
  },
  {
    value: "Козлова Е.В.",
    label: "Козлова Е.В.",
  },
  {
    value: "Колесник В.Н.",
    label: "Колесник В.Н.",
  },
  {
    value: "Колесникова Н.П.",
    label: "Колесникова Н.П.",
  },
  {
    value: "Колмакова М.А.",
    label: "Колмакова М.А.",
  },
  {
    value: "Комаров П.Д.",
    label: "Комаров П.Д.",
  },
  {
    value: "Комиссаров Е.В.",
    label: "Комиссаров Е.В.",
  },
  {
    value: "Копылова О.Б.",
    label: "Копылова О.Б.",
  },
  {
    value: "Королева А.А.",
    label: "Королева А.А.",
  },
  {
    value: "Короткова Е.С.",
    label: "Короткова Е.С.",
  },
  {
    value: "Кострюкова К.П.",
    label: "Кострюкова К.П.",
  },
  {
    value: "Костырев А.М.",
    label: "Костырев А.М.",
  },
  {
    value: "Костюков А.В.",
    label: "Костюков А.В.",
  },
  {
    value: "Костюнина Л.Ю.",
    label: "Костюнина Л.Ю.",
  },
  {
    value: "Котенева В.В.",
    label: "Котенева В.В.",
  },
  {
    value: "Котова М.Н.",
    label: "Котова М.Н.",
  },
  {
    value: "Крапчетова О.В.",
    label: "Крапчетова О.В.",
  },
  {
    value: "Красова И.В.",
    label: "Красова И.В.",
  },
  {
    value: "Криворучко А.В.",
    label: "Криворучко А.В.",
  },
  {
    value: "Крутовская Л.А.",
    label: "Крутовская Л.А.",
  },
  {
    value: "Крылова О.К.",
    label: "Крылова О.К.",
  },
  {
    value: "Кудряшова М.А.",
    label: "Кудряшова М.А.",
  },
  {
    value: "Кузнецов А.А.",
    label: "Кузнецов А.А.",
  },
  {
    value: "Кузнецов В.С.",
    label: "Кузнецов В.С.",
  },
  {
    value: "Куленёва Е.Ю.",
    label: "Куленёва Е.Ю.",
  },
  {
    value: "Кулик А.Ю.",
    label: "Кулик А.Ю.",
  },
  {
    value: "Куликова (Смыкова) И.В.",
    label: "Куликова (Смыкова) И.В.",
  },
  {
    value: "Курбанов Д.Р.",
    label: "Курбанов Д.Р.",
  },
  {
    value: "Куропов А.Н.",
    label: "Куропов А.Н.",
  },
  {
    value: "Курышева Н.С.",
    label: "Курышева Н.С.",
  },
  {
    value: "Кучина Н.С.",
    label: "Кучина Н.С.",
  },
  {
    value: "Лаврова Е.Л.",
    label: "Лаврова Е.Л.",
  },
  {
    value: "Лактюшин В.А.",
    label: "Лактюшин В.А.",
  },
  {
    value: "Ларин А.А.",
    label: "Ларин А.А.",
  },
  {
    value: "Ларина Н.Г.",
    label: "Ларина Н.Г.",
  },
  {
    value: "Ларкина М.А.",
    label: "Ларкина М.А.",
  },
  {
    value: "Латышева О.А.",
    label: "Латышева О.А.",
  },
  {
    value: "Лебедева В.Г.",
    label: "Лебедева В.Г.",
  },
  {
    value: "Лебедева Е.Г.",
    label: "Лебедева Е.Г.",
  },
  {
    value: "Левашова (савушкина) В.Е.",
    label: "Левашова (савушкина) В.Е.",
  },
  {
    value: "Левченко М.К.",
    label: "Левченко М.К.",
  },
  {
    value: "Лекомцева Ю.Б.",
    label: "Лекомцева Ю.Б.",
  },
  {
    value: "Ленская Е.А.",
    label: "Ленская Е.А.",
  },
  {
    value: "Леонова М.Н.",
    label: "Леонова М.Н.",
  },
  {
    value: "Ливенцева Е.В.",
    label: "Ливенцева Е.В.",
  },
  {
    value: "Лисовицкий С.П.",
    label: "Лисовицкий С.П.",
  },
  {
    value: "Лифанова Н.В.",
    label: "Лифанова Н.В.",
  },
  {
    value: "Лихман Ю.А.",
    label: "Лихман Ю.А.",
  },
  {
    value: "Лоскутова А.Е.",
    label: "Лоскутова А.Е.",
  },
  {
    value: "Лунина Н.Н.",
    label: "Лунина Н.Н.",
  },
  {
    value: "Лутов А.В.",
    label: "Лутов А.В.",
  },
  {
    value: "Луценко В.И.",
    label: "Луценко В.И.",
  },
  {
    value: "Лысенко А.Н.",
    label: "Лысенко А.Н.",
  },
  {
    value: "Магурин Е.Л.",
    label: "Магурин Е.Л.",
  },
  {
    value: "Майорова А.В.",
    label: "Майорова А.В.",
  },
  {
    value: "Макаренков Д.В.",
    label: "Макаренков Д.В.",
  },
  {
    value: "Максимова Е.А.",
    label: "Максимова Е.А.",
  },
  {
    value: "Мальцев П.В.",
    label: "Мальцев П.В.",
  },
  {
    value: "Мамаева Е.Ю.",
    label: "Мамаева Е.Ю.",
  },
  {
    value: "Мариненко К.А.",
    label: "Мариненко К.А.",
  },
  {
    value: "Мартыненко А.А.",
    label: "Мартыненко А.А.",
  },
  {
    value: "Маслов А.В.",
    label: "Маслов А.В.",
  },
  {
    value: "Матвеева М.С.",
    label: "Матвеева М.С.",
  },
  {
    value: "Мелехин П.В.",
    label: "Мелехин П.В.",
  },
  {
    value: "Менделеева О.А.",
    label: "Менделеева О.А.",
  },
  {
    value: "Мерзляков Д.С.",
    label: "Мерзляков Д.С.",
  },
  {
    value: "Меркулов А.А.",
    label: "Меркулов А.А.",
  },
  {
    value: "Минин С.Ю.",
    label: "Минин С.Ю.",
  },
  {
    value: "Миславская О.В.",
    label: "Миславская О.В.",
  },
  {
    value: "Михайловская М.А.",
    label: "Михайловская М.А.",
  },
  {
    value: "Мищенко Д.И.",
    label: "Мищенко Д.И.",
  },
  {
    value: "Мордвина Ю.С.",
    label: "Мордвина Ю.С.",
  },
  {
    value: "Морозов М.В.",
    label: "Морозов М.В.",
  },
  {
    value: "Моторин А.В.",
    label: "Моторин А.В.",
  },
  {
    value: "Мохов А.В.",
    label: "Мохов А.В.",
  },
  {
    value: "Назаренко А.П.",
    label: "Назаренко А.П.",
  },
  {
    value: "Назарова Е.Ю.",
    label: "Назарова Е.Ю.",
  },
  {
    value: "Назарова Н.Н.",
    label: "Назарова Н.Н.",
  },
  {
    value: "Найденов Е.М.",
    label: "Найденов Е.М.",
  },
  {
    value: "Неверова Т.В.",
    label: "Неверова Т.В.",
  },
  {
    value: "Ненашева С.А.",
    label: "Ненашева С.А.",
  },
  {
    value: "Неудахин Д.В.",
    label: "Неудахин Д.В.",
  },
  {
    value: "Никитин В.Ф.",
    label: "Никитин В.Ф.",
  },
  {
    value: "Никитин М.В.",
    label: "Никитин М.В.",
  },
  {
    value: "Никитина А.В.",
    label: "Никитина А.В.",
  },
  {
    value: "Никиточкина З.В.",
    label: "Никиточкина З.В.",
  },
  {
    value: "Никишина Н.В.",
    label: "Никишина Н.В.",
  },
  {
    value: "Николаева Е.С.",
    label: "Николаева Е.С.",
  },
  {
    value: "Оганова В.А.",
    label: "Оганова В.А.",
  },
  {
    value: "Окунева Ю.С.",
    label: "Окунева Ю.С.",
  },
  {
    value: "Оленев В.Н.",
    label: "Оленев В.Н.",
  },
  {
    value: "Орешкина И.В.",
    label: "Орешкина И.В.",
  },
  {
    value: "Орлова Е.С.",
    label: "Орлова Е.С.",
  },
  {
    value: "Орлова З.П.",
    label: "Орлова З.П.",
  },
  {
    value: "Орлова М.Е.",
    label: "Орлова М.Е.",
  },
  {
    value: "Орловцева Е.Ю.",
    label: "Орловцева Е.Ю.",
  },
  {
    value: "Павленко М.И.",
    label: "Павленко М.И.",
  },
  {
    value: "Панова К.К.",
    label: "Панова К.К.",
  },
  {
    value: "Пантыкина О.В.",
    label: "Пантыкина О.В.",
  },
  {
    value: "Пахомова Е.П.",
    label: "Пахомова Е.П.",
  },
  {
    value: "Пашевич И.И.",
    label: "Пашевич И.И.",
  },
  {
    value: "Петрова А.Г.",
    label: "Петрова А.Г.",
  },
  {
    value: "Петухов Д.В.",
    label: "Петухов Д.В.",
  },
  {
    value: "Петухова Л.А.",
    label: "Петухова Л.А.",
  },
  {
    value: "Пинина О.В.",
    label: "Пинина О.В.",
  },
  {
    value: "Пинтелина И.С.",
    label: "Пинтелина И.С.",
  },
  {
    value: "Пирогова Е.С.",
    label: "Пирогова Е.С.",
  },
  {
    value: "Плаксина О.А.",
    label: "Плаксина О.А.",
  },
  {
    value: "Подопригоров С.Г.",
    label: "Подопригоров С.Г.",
  },
  {
    value: "Полковников С.В.",
    label: "Полковников С.В.",
  },
  {
    value: "Полунина Л.А.",
    label: "Полунина Л.А.",
  },
  {
    value: "Пономарева Н.А.",
    label: "Пономарева Н.А.",
  },
  {
    value: "Поспелова Е.В.",
    label: "Поспелова Е.В.",
  },
  {
    value: "Потапенко С.В.",
    label: "Потапенко С.В.",
  },
  {
    value: "Потехина Е.В.",
    label: "Потехина Е.В.",
  },
  {
    value: "Похилько К.А.",
    label: "Похилько К.А.",
  },
  {
    value: "Приданникова Н.В.",
    label: "Приданникова Н.В.",
  },
  {
    value: "Прохорова С.М.",
    label: "Прохорова С.М.",
  },
  {
    value: "Пугачева Д.К.",
    label: "Пугачева Д.К.",
  },
  {
    value: "Рагимова С.Э.",
    label: "Рагимова С.Э.",
  },
  {
    value: "Расновский А.В.",
    label: "Расновский А.В.",
  },
  {
    value: "Рахматов Ю.А.",
    label: "Рахматов Ю.А.",
  },
  {
    value: "Репникова Н.В.",
    label: "Репникова Н.В.",
  },
  {
    value: "Рожков А.В.",
    label: "Рожков А.В.",
  },
  {
    value: "Романова В.А.",
    label: "Романова В.А.",
  },
  {
    value: "Рудакова Ю.Г.",
    label: "Рудакова Ю.Г.",
  },
  {
    value: "Рыбак А.Е.",
    label: "Рыбак А.Е.",
  },
  {
    value: "Рысенков Д.А.",
    label: "Рысенков Д.А.",
  },
  {
    value: "Рябцев С.А.",
    label: "Рябцев С.А.",
  },
  {
    value: "Савостьянова Е.Б.",
    label: "Савостьянова Е.Б.",
  },
  {
    value: "Сальков А.О.",
    label: "Сальков А.О.",
  },
  {
    value: "Сафина Ю.Р.",
    label: "Сафина Ю.Р.",
  },
  {
    value: "Севалкин А.А.",
    label: "Севалкин А.А.",
  },
  {
    value: "Седышев А.Г.",
    label: "Седышев А.Г.",
  },
  {
    value: "Семенихина А.Ю.",
    label: "Семенихина А.Ю.",
  },
  {
    value: "Семёнова Л.В.",
    label: "Семёнова Л.В.",
  },
  {
    value: "Семенова Н.А.",
    label: "Семенова Н.А.",
  },
  {
    value: "Семина О.Н.",
    label: "Семина О.Н.",
  },
  {
    value: "Сизинцева М.В.",
    label: "Сизинцева М.В.",
  },
  {
    value: "Сиратегян В.К.",
    label: "Сиратегян В.К.",
  },
  {
    value: "Скобинова А.В.",
    label: "Скобинова А.В.",
  },
  {
    value: "Смагин Д.В.",
    label: "Смагин Д.В.",
  },
  {
    value: "Смирнова М.М.",
    label: "Смирнова М.М.",
  },
  {
    value: "Соболь О.А.",
    label: "Соболь О.А.",
  },
  {
    value: "Сокова А.О.",
    label: "Сокова А.О.",
  },
  {
    value: "Соколова Т.В.",
    label: "Соколова Т.В.",
  },
  {
    value: "Соловьева Г.В.",
    label: "Соловьева Г.В.",
  },
  {
    value: "Соловьева Н.Б.",
    label: "Соловьева Н.Б.",
  },
  {
    value: "Солодкова Н.В.",
    label: "Солодкова Н.В.",
  },
  {
    value: "Солопова О.Н.",
    label: "Солопова О.Н.",
  },
  {
    value: "Спицына С.С.",
    label: "Спицына С.С.",
  },
  {
    value: "Стеклиев А.В.",
    label: "Стеклиев А.В.",
  },
  {
    value: "Степина Е.В.",
    label: "Степина Е.В.",
  },
  {
    value: "Стрельцова Г.Ю.",
    label: "Стрельцова Г.Ю.",
  },
  {
    value: "Строева Е.В.",
    label: "Строева Е.В.",
  },
  {
    value: "Ступин П.П.",
    label: "Ступин П.П.",
  },
  {
    value: "Суворов А.А.",
    label: "Суворов А.А.",
  },
  {
    value: "Суворова А.С.",
    label: "Суворова А.С.",
  },
  {
    value: "Суздаль Е.А.",
    label: "Суздаль Е.А.",
  },
  {
    value: "Сушкова О.А.",
    label: "Сушкова О.А.",
  },
  {
    value: "Сырова М.Л.",
    label: "Сырова М.Л.",
  },
  {
    value: "Таланина Г.Н.",
    label: "Таланина Г.Н.",
  },
  {
    value: "Тарбаева И.А.",
    label: "Тарбаева И.А.",
  },
  {
    value: "Терехова А.А.",
    label: "Терехова А.А.",
  },
  {
    value: "Тимакова А.Ю.",
    label: "Тимакова А.Ю.",
  },
  {
    value: "Титова И.В.",
    label: "Титова И.В.",
  },
  {
    value: "Тихомирова Е.А.",
    label: "Тихомирова Е.А.",
  },
  {
    value: "Ткачева Н.А.",
    label: "Ткачева Н.А.",
  },
  {
    value: "Толстой А.В.",
    label: "Толстой А.В.",
  },
  {
    value: "Тришин Д.В.",
    label: "Тришин Д.В.",
  },
  {
    value: "Туманина С.В.",
    label: "Туманина С.В.",
  },
  {
    value: "Турланова О.И.",
    label: "Турланова О.И.",
  },
  {
    value: "Устинов А.А.",
    label: "Устинов А.А.",
  },
  {
    value: "Устинова О.В.",
    label: "Устинова О.В.",
  },
  {
    value: "Ухналева С.В.",
    label: "Ухналева С.В.",
  },
  {
    value: "Федина О.А.",
    label: "Федина О.А.",
  },
  {
    value: "Федоров А.М.",
    label: "Федоров А.М.",
  },
  {
    value: "Федосов Н.А.",
    label: "Федосов Н.А.",
  },
  {
    value: "Федотов Д.И.",
    label: "Федотов Д.И.",
  },
  {
    value: "Фигурина Н.Н.",
    label: "Фигурина Н.Н.",
  },
  {
    value: "Филатов А.Ю.",
    label: "Филатов А.Ю.",
  },
  {
    value: "Филимонов Е.В.",
    label: "Филимонов Е.В.",
  },
  {
    value: "Фильченко М.С.",
    label: "Фильченко М.С.",
  },
  {
    value: "Фролова Ю.В.",
    label: "Фролова Ю.В.",
  },
  {
    value: "Химичева И.А.",
    label: "Химичева И.А.",
  },
  {
    value: "Хомяков С.А.",
    label: "Хомяков С.А.",
  },
  {
    value: "Хомякова Н.А.",
    label: "Хомякова Н.А.",
  },
  {
    value: "Хохлова А.А.",
    label: "Хохлова А.А.",
  },
  {
    value: "Хренова Т.В.",
    label: "Хренова Т.В.",
  },
  {
    value: "Чайковская А.Г.",
    label: "Чайковская А.Г.",
  },
  {
    value: "Чеботарь В.Д.",
    label: "Чеботарь В.Д.",
  },
  {
    value: "Чепрасова Н.В.",
    label: "Чепрасова Н.В.",
  },
  {
    value: "Череповская О.П.",
    label: "Череповская О.П.",
  },
  {
    value: "Черникова Ю.В.",
    label: "Черникова Ю.В.",
  },
  {
    value: "Чеченков К.В.",
    label: "Чеченков К.В.",
  },
  {
    value: "Чечко Л.Н.",
    label: "Чечко Л.Н.",
  },
  {
    value: "Чистова О.Ю.",
    label: "Чистова О.Ю.",
  },
  {
    value: "Чугаев Ю.А.",
    label: "Чугаев Ю.А.",
  },
  {
    value: "Чумаченко С.В.",
    label: "Чумаченко С.В.",
  },
  {
    value: "Шабашева Н.Е.",
    label: "Шабашева Н.Е.",
  },
  {
    value: "Шалашова И.А.",
    label: "Шалашова И.А.",
  },
  {
    value: "Шанина Т.В.",
    label: "Шанина Т.В.",
  },
  {
    value: "Шарапова Е.В.",
    label: "Шарапова Е.В.",
  },
  {
    value: "Шевьева Н.С.",
    label: "Шевьева Н.С.",
  },
  {
    value: "Шелепов К.В.",
    label: "Шелепов К.В.",
  },
  {
    value: "Шелепова Ю.В.",
    label: "Шелепова Ю.В.",
  },
  {
    value: "Шелкошвейн Е.В.",
    label: "Шелкошвейн Е.В.",
  },
  {
    value: "Шестаков А.В.",
    label: "Шестаков А.В.",
  },
  {
    value: "Шикарева С.А.",
    label: "Шикарева С.А.",
  },
  {
    value: "Шилкин Г.А.",
    label: "Шилкин Г.А.",
  },
  {
    value: "Шипиков А.И.",
    label: "Шипиков А.И.",
  },
  {
    value: "Шпарийчук Н.Е.",
    label: "Шпарийчук Н.Е.",
  },
  {
    value: "Ягудина А.В.",
    label: "Ягудина А.В.",
  },
  {
    value: "Яковлев И.А.",
    label: "Яковлев И.А.",
  },
  {
    value: "Якубаев Р.Г.",
    label: "Якубаев Р.Г.",
  },
  {
    value: "Яни Д.П.",
    label: "Яни Д.П.",
  },
];
