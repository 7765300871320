import { SSPK, SSTK } from "../constants/storage-keys";
import { IProfile } from "../types/Auth.types";

export const getSessionStorage = (key: string) => {
  try {
    return sessionStorage?.getItem(key) || null;
  } catch (e) {
    console.error("Error accessing localStorage:", e);
  }
};

export const getSessionStorageCredentials = () => {
  try {
    return getSessionStorage(SSTK);
  } catch {
    return null;
  }
};

export const getSessionStorageProfile = () => {
  try {
    return (JSON.parse(getSessionStorage(SSPK) || "null") as IProfile) || null;
  } catch {
    return null;
  }
};
