import React, { useState } from "react";
import "./Home.scss";
import { useNavigate } from "react-router";

import { H } from "../../components/UI/Text/H";
import { Button } from "../../components/UI/Button/Button";
import Modal, { IModal } from "../../components/smart/Modal";
import { ContactUsForm } from "../../components/smart/Header/components/Modals/ContactUsForm";

const HomeCards = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const homeCardsData = [
    {
      title: "Первичная консультация",
      texts: [
        {
          value:
            "Первым делом, люди пытаются найти в интернете любую информацию о проблеме, с которой столкнулись",
          isColored: false,
        },
        {
          value:
            "Информация бывает часто разрозненная и противоречивая, что добавляет только больше стресса в итак непростой ситуации",
          isColored: true,
        },
        {
          value:
            "Специалисты команды Srok.net готовы оказать первичную помощь в трудной ситуации",
          isColored: false,
        },
      ],
      button: { title: "Напишите нам", action: () => setShowModal(true) },
    },
    {
      title: "Поиск юриста",
      texts: [
        {
          value:
            "Получив представление о проблеме, люди хотят найти юриста/адвоката, который специализируется в нужной области",
          isColored: false,
        },
        {
          value:
            "Зачастую это первое столкновение человека с проблемами с законом Не имея большого опыта и ограниченые сроки, человек легко попадает к недобросовестным специалистам",
          isColored: true,
        },
        {
          value:
            "Srok.net позволяет найти юриста/адвоката, с подтвержденными делами из открытых источников. Помимо поиска специалиста, с помощью Srok.net вы можете также проверить реальную историю вашего юриста/адвоката",
          isColored: false,
        },
      ],
      button: { title: "Найти юриста", action: () => setShowModal2(true) },
    },
    {
      title: "Разработка стратегии и сопровождение по ходу дела",
      texts: [
        {
          value:
            "Совместно с юристом / адвокатом разрабатывается стратегия ведения дела, а также юрист сопровождает на предварительном следствии,  мере пресечения, в суде первой инстанции",
          isColored: false,
        },
        {
          value:
            "Благодаря аналитической базе Srok.net вы сможете точно понимать как завершает дела конкретный судья в конкретном суде",
          isColored: false,
        },
        {
          value:
            "А с помощью Калькулятора сможете спрогнозировать возможные сроки выхода на УДО и ПТР",
          isColored: false,
        },
      ],
      button: { title: "Узнать срок", action: () => navigate("/freedom") },
    },
    {
      title: "Смягчение решения суда",
      texts: [
        {
          value:
            "Если решение суда хуже ожидаемого, начинается работа над смягчением наказания",
          isColored: false,
        },
        {
          value:
            "С помощью отчетов Srok.net вы сможете узнать статистику по смегчению у конкретной судьи, а также какие смегчающие обстаятельства у судьи работали",
          isColored: false,
        },
        {
          value:
            "Калькулятор позволит спрогнозировать прогнозное решение по апелляции",
          isColored: false,
        },
      ],
      button: { title: "Прогноз ", action: () => navigate("/calculator") },
    },
  ];

  const showModalSettings: IModal = {
    active: showModal,
    setActive: setShowModal,
  };
  const showModalSettings2: IModal = {
    active: showModal2,
    setActive: setShowModal2,
  };
  return (
    <div className="home-cards">
      {homeCardsData.map((card, idx) => (
        <div className="home-cards-item" key={idx}>
          <div className="home-cards-item-top">
            <div className="home-cards-item-number">{idx + 1}</div>
            <H variant="hd" sx={{ color: "#0C64C5" }}>
              {card.title}
            </H>
          </div>
          <div className="home-cards-item-block">
            {idx < homeCardsData.length - 1 && (
              <div className="home-cards-item-block-line" />
            )}
            <div className="home-cards-item-block-row">
              <div className="home-cards-item-block-texts">
                {card.texts.map((text, idx) => (
                  <H
                    key={idx}
                    variant="sm"
                    sx={
                      text.isColored
                        ? { color: "#0C64C5", fontWeight: "bold" }
                        : { fontWeight: "500" }
                    }
                  >
                    {text.value}
                  </H>
                ))}
              </div>
              <div className="home-cards-item-block-button">
                <Button
                  color="primary"
                  label={card.button.title}
                  onClick={card.button.action}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
      <Modal {...showModalSettings}>
        <ContactUsForm setShowModal={setShowModal} />
      </Modal>
      <Modal {...showModalSettings2}>
        <ContactUsForm
          setShowModal={setShowModal2}
          customSubtitle={
            "Функционал находится в разработке, но мы готовы проконсультировать вас в частном порядке"
          }
        />
      </Modal>
    </div>
  );
};

const Home = () => {
  return (
    <>
      <div className="home-title-content">
        <h3 className="home-title-content-text">
          Помогаем на всем пути правосудия
        </h3>
        <H variant="md">
          Специалисты и собранная аналитика готовы помочь <br /> вам в трудной
          ситуации
        </H>
      </div>
      <div className="home-subtitle-content">
        <h3 className="home-subtitle-content-text">
          Оказались в трудной ситуации?
        </h3>
        <H variant="md">
          Давайте посмотрим, как выглядит типичный путь людей с вашей проблемой
        </H>
      </div>

      <HomeCards />
    </>
  );
};

export default Home;
