import cn from "classnames";
import React, {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  ReactNode,
} from "react";
import "./Button.scss";

import IconWrapper from "../IconWrapper";

export type ButtonColors = "secondary" | "primary";

export interface ButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  label?: string;
  icon?: ReactNode;
  sx?: React.CSSProperties;
  iconSizeLimiter?: number;
  color?: ButtonColors;
  loading?: boolean;
}

export const Button: React.FC<ButtonProps> = (props) => {
  const {
    icon,
    label,
    sx,
    disabled,
    iconSizeLimiter,
    color,
    className,
    loading,
    ...rest
  } = props;

  return (
    <button
      {...rest}
      style={{ ...sx }}
      disabled={disabled || loading}
      className={cn("icon-button", className && className, {
        "button-disabled": disabled,
        "button-loading": loading,
        "button-primary": color === "primary",
        "button-secondary": color === "secondary",
      })}
    >
      {!loading && (
        <>
          {icon && (
            <IconWrapper size={iconSizeLimiter ?? 22}>{icon}</IconWrapper>
          )}
          {label && label}
        </>
      )}
    </button>
  );
};
