import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { ConnectionInstance as Connection } from "src/utils/constants/connection";

import { OnFreedomReportType } from "../../utils/types/OnFreedom.types";

export const onFreedomApi = createApi({
  reducerPath: "onFreedomApi/api",
  baseQuery: fetchBaseQuery({
    baseUrl: Connection.REST_HOST,
  }),
  endpoints: (build) => ({
    createReport: build.mutation<
      any,
      { body: any; reportType: OnFreedomReportType }
    >({
      query: ({ body, reportType }) => ({
        url: `function/func_srok.php?api=${reportType}`,
        method: "POST",
        body,
      }),
    }),
    contactFormSendMail: build.mutation<any, unknown>({
      query: (body) => ({
        url: "function/func_srok.php?api=sendmail",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useCreateReportMutation, useContactFormSendMailMutation } =
  onFreedomApi;
