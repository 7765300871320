import { useEffect, useState } from "react";

const mediaQueriesConstants = {
  minDesktop: "(min-width: 1261px)",
  minLargeTablet: "(min-width: 997px)",
  min900: "(min-width: 900px)",
  minMediumTablet: "(min-width: 769px)",
  minSmallTablet: "(min-width: 577px)",
  mobile: "(max-width: 576px)",
  tablet: "(max-width: 768px)",
};

function useMediaQuery(query: string): boolean {
  const getMatches = (query: string): boolean => {
    if (typeof window !== "undefined") {
      return window.matchMedia(query).matches;
    }
    return false;
  };

  const [matches, setMatches] = useState<boolean>(getMatches(query));

  function handleChange() {
    setMatches(getMatches(query));
  }

  useEffect(() => {
    const matchMedia = window.matchMedia(query);

    handleChange();

    if (matchMedia.addListener) {
      matchMedia.addListener(handleChange);
    } else {
      matchMedia.addEventListener("change", handleChange);
    }

    return () => {
      if (matchMedia.removeListener) {
        matchMedia.removeListener(handleChange);
      } else {
        matchMedia.removeEventListener("change", handleChange);
      }
    };
  }, [query]);

  return matches;
}

export { useMediaQuery, mediaQueriesConstants };
