import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

// import { Connection } from "../../../utils/enums/common.enums";
import { ConnectionInstance as Connection } from "src/utils/constants/connection";

import { generateMD5Hash } from "../../utils/helpers/crypto";
import {
  IAuthEnterResponse,
  IProfileReportResponse,
} from "../../utils/types/Auth.types";
import { getSessionStorageCredentials } from "../../utils/helpers/storage.helpers";

export const lawsInfoPageApi = createApi({
  reducerPath: "calculatorResultApi/api",
  baseQuery: fetchBaseQuery({
    baseUrl: Connection.REST_HOST,
  }),
  endpoints: (build) => ({
    authEnter: build.mutation<
      IAuthEnterResponse,
      { password: string; login: string }
    >({
      query: (body) => ({
        url: "function/func_srok.php?api=enter",
        method: "POST",
        body: { password: generateMD5Hash(body.password), login: body.login },
      }),
    }),
    authRegistr: build.mutation<any, { password: string; login: string }>({
      query: (body) => ({
        url: "function/func_srok.php?api=registrate",
        method: "POST",
        body: { password: generateMD5Hash(body.password), login: body.login },
      }),
    }),
    profileHistory: build.mutation<IProfileReportResponse, void>({
      query: () => ({
        url: "function/func_srok.php?api=report_history",
        method: "POST",
        body: { parametr: getSessionStorageCredentials() },
      }),
    }),
  }),
});

export const {
  useAuthEnterMutation,
  useAuthRegistrMutation,
  useProfileHistoryMutation,
} = lawsInfoPageApi;
