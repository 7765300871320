import { IProfile } from "../types/Auth.types";

export const firstNameLastNameHandler = (profile: IProfile | null) => {
  let res = "";
  if (profile?.firstname) res += profile.firstname;
  if (profile?.firstname && profile.lastname) res += " ";
  if (profile?.lastname) res += profile.lastname;

  if (!res) res = "Заполните профиль";

  return res;
};

export const toDate = (date: string, showTime?: boolean, timezone?: string) => {
  if (Date.parse(date)) {
    if (showTime) {
      if (timezone) {
        return new Intl.DateTimeFormat("ru-Ru", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          timeZone: timezone,
        }).format(new Date(date));
      } else {
        return new Intl.DateTimeFormat("ru-Ru", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }).format(new Date(date));
      }
    } else {
      return new Intl.DateTimeFormat("ru-Ru", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }).format(new Date(date));
    }
  }
};
